import { dev } from "$app/environment";

export const title = "Voice Studio";
export const description = "Cool English Voice Studio";

export const SPEECH_TOKEN_CACHE_TIME = 9 * 60; // 9 minutes
export const VOICE_LIST_CACHE_TIME = 1 * 60 * 60; // 1 hour
export const SPEECH_SYNTHESIS_CACHE_TIME = 24 * 60 * 60; // 24 hours

export const ACCEPTED_CHAR = /^[a-zA-Z0-9 .,!?':;()]$/;
export const WORD_BREAK = /[ .,!?':;()]/;

export const TOKEN_ENDPOINT = dev ? "https://voice-studio.ichatbot.com.tw/api/tk" : "/api/tk";
export const SPEAKER_ENDPOINT = dev
	? "https://voice-studio.ichatbot.com.tw/api/voice_list"
	: "/api/voice_list";

export const LOCALE_ALLOWLIST = ["English (United States)", "English (United Kingdom)"];
